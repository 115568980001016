import { splitProps } from 'solid-js'

type Props = ComponentProps<'h1'>

export function H1(props: Props) {
  return <Presenter {...props} />
}

function Presenter(props: Props) {
  const [component, attributes] = splitProps(props, ['children'])
  return <h1 {...attributes}>{component.children}</h1>
}
